import { Component, input } from '@angular/core';

@Component({
  selector: 'app-ingredient-image',
  templateUrl: './ingredient-image.component.html',
})
export class IngredientImageComponent {
  readonly svg = input<string>();
  readonly color = input.required<string>();
}
