import { Component, OnInit, inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { UserApi } from '@app/_api/apis/user.api';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { noop } from 'rxjs';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  imports: [ReactiveFormsModule],
})
export class UserModalComponent implements OnInit {
  private readonly userApi = inject(UserApi);
  private readonly modalService = inject(NgbModal);
  private readonly modal = inject(NgbActiveModal);

  error = false;

  readonly form = new FormGroup({
    id: new FormControl<number | undefined>(undefined, {
      nonNullable: true,
    }),
    email: new FormControl<string>('', {
      nonNullable: true,
      validators: [
        Validators.required,
        Validators.email,
        Validators.maxLength(255),
      ],
    }),
    password: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.maxLength(255)],
    }),
  });

  ngOnInit(): void {
    if (!this.form.value.id) {
      this.form
        .get('password')!
        .setValidators([Validators.required, Validators.maxLength(255)]);
    }
  }

  save(): void {
    this.userApi.saveOne(this.form.getRawValue()).subscribe({
      next: user => {
        this.modal.close(user);
      },
      error: () => {
        this.error = true;

        setTimeout(() => {
          this.error = false;
        }, 200);
      },
    });
  }

  delete(): void {
    const modal = this.modalService.open(DeleteModalComponent);
    const component: DeleteModalComponent = modal.componentInstance;

    component.name = 'user';

    modal.result
      .then(() => {
        this.userApi.deleteOneById(this.form.value.id!).subscribe(() => {
          this.modal.close();
        });
      })
      .catch(noop);
  }
}
