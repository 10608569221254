import { Component, inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { IngredientApi } from '@app/_api/apis/ingredient.api';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { noop } from 'rxjs';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';
import { IngredientImageComponent } from '../ingredient-image/ingredient-image.component';

@Component({
  selector: 'app-ingredient-modal',
  templateUrl: './ingredient-modal.component.html',
  imports: [ReactiveFormsModule, IngredientImageComponent],
})
export class IngredientModalComponent {
  private readonly ingredientApi = inject(IngredientApi);
  private readonly modalService = inject(NgbModal);
  private readonly modal = inject(NgbActiveModal);

  error = false;

  readonly form = new FormGroup({
    id: new FormControl<number | undefined>(undefined, {
      nonNullable: true,
    }),
    names: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, Validators.maxLength(255)],
    }),
    color: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    svg: new FormControl<string>('', {
      nonNullable: true,
      validators: [],
    }),
  });

  upload(files: FileList): void {
    const file = files.item(0);

    if (file) {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        const svg = reader.result as string;

        const d = svg
          .match(/\sd="([a-zA-Z0-9.,\- ]+)"/)?.[1]
          ?.replace(/[+-]?(?:0|[1-9]\d*)(?:\.\d+)?(?:[eE][+-]?\d+)?/g, n =>
            Number(n).toFixed(1),
          );

        if (d) {
          this.form.get('svg')!.setValue(d);
          this.form.get('svg')!.markAsDirty();
        }
      };
    }
  }

  save(): void {
    this.ingredientApi.saveOne(this.form.getRawValue()).subscribe({
      next: ingredient => {
        this.modal.close(ingredient);
      },
      error: () => {
        this.error = true;

        setTimeout(() => {
          this.error = false;
        }, 200);
      },
    });
  }

  delete(): void {
    const modal = this.modalService.open(DeleteModalComponent);
    const component: DeleteModalComponent = modal.componentInstance;

    component.name = 'ingredient';

    modal.result
      .then(() => {
        this.ingredientApi.deleteOneById(this.form.value.id!).subscribe(() => {
          this.modal.close();
        });
      })
      .catch(noop);
  }
}
