import { Component, inject } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { AuthService } from '@app/services';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  imports: [RouterLink],
})
export class AdminComponent {
  private readonly authService = inject(AuthService);
  private readonly router = inject(Router);

  get user(): string | undefined {
    return this.authService.user;
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate(['/']);
  }
}
