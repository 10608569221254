import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RedirectCommand,
  ResolveFn,
  Router,
} from '@angular/router';
import { Recipe, RecipeApi } from '@app/_api';
import { catchError, of } from 'rxjs';

export const recipeResolver: ResolveFn<Recipe> = (
  route: ActivatedRouteSnapshot,
) => {
  const redirect = new RedirectCommand(inject(Router).parseUrl('/'));

  return inject(RecipeApi)
    .findOneById(+route.params['id'])
    .pipe(catchError(() => of(redirect)));
};
