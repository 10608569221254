import { Component, OnInit, inject } from '@angular/core';
import { Ingredient, RecipeApi } from '@app/_api';
import { simplify } from '@app/functions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { noop } from 'rxjs';
import { IngredientModalComponent } from '../ingredient-modal/ingredient-modal.component';

@Component({
  selector: 'app-missing-ingredients-modal',
  templateUrl: './missing-ingredients-modal.component.html',
})
export class MissingIngredientsModalComponent implements OnInit {
  private readonly recipeApi = inject(RecipeApi);
  private readonly modalService = inject(NgbModal);

  ingredients: Ingredient[] = [];

  names: string[] = [];

  ngOnInit(): void {
    this.recipeApi.find().subscribe(recipes => {
      const existing = this.ingredients.flatMap(i =>
        simplify(i.names).split('\n'),
      );

      this.names = recipes
        .flatMap(r => simplify(r.ingredients).split('\n'))
        .filter(Boolean)
        .filter(v => {
          const ingredientParts = v.split(' ');

          for (let i = 0; i < ingredientParts.length; i++) {
            const name = ingredientParts.slice(i).join(' ');
            const ingredient = existing.find(i => i === name);
            if (ingredient) {
              return false;
            }
          }

          return true;
        })
        .map(n => n.split(' ').at(-1)!)
        .filter((v, i, a) => a.indexOf(v) === i)
        .map(n => n[0].toUpperCase() + n.slice(1));

      this.names.sort();
    });
  }

  create(name: string): void {
    const modal = this.modalService.open(IngredientModalComponent, {
      size: 'lg',
    });
    const component: IngredientModalComponent = modal.componentInstance;

    component.form.patchValue({ names: name });

    modal.result
      .then((ingredient: Ingredient) => {
        const names = simplify(ingredient.names).split('\n');

        this.names = this.names.filter(n => !names.includes(simplify(n)));

        this.ingredients.push(ingredient);
      })
      .catch(noop);
  }
}
