import { Component, computed, input } from '@angular/core';
import { Ingredient, Recipe } from '@app/_api';
import { distribute, simplify } from '@app/functions';

@Component({
  selector: 'app-ingredients-image',
  templateUrl: './ingredients-image.component.html',
})
export class IngredientsImageComponent {
  readonly recipe = input.required<Recipe>();
  readonly ingredients = input.required<Record<string, Ingredient>>();

  readonly images = computed(() => {
    const ingredients = this.ingredients();

    return distribute(
      simplify(this.recipe().ingredients)
        .split('\n')
        .map(n => {
          const ingredientParts = n.split(' ');

          for (let i = 0; i < ingredientParts.length; i++) {
            const name = ingredientParts.slice(i).join(' ');
            const ingredient = ingredients[name];
            if (ingredient) {
              return ingredient;
            }
          }

          return undefined;
        })
        .filter((i): i is Ingredient => i != null),
      i => !i.svg,
    )
      .map((ingredient, i, a) => ({
        svg: ingredient.svg,
        color: ingredient.color,
        x: Math.sin((2 * Math.PI * i) / a.length),
        y: Math.cos((2 * Math.PI * i) / a.length),
        th: -40 * ((((i / a.length) * 2 + 1.5) % 1) - 0.5),
      }))
      .sort((a, b) => a.svg.length - b.svg.length);
  });

  readonly W = 50;
  readonly H = 35;
  readonly C = 50;
}
