import { Component, inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { AuthService } from '@app/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  imports: [ReactiveFormsModule],
})
export class LoginModalComponent {
  private readonly authService = inject(AuthService);
  private readonly modal = inject(NgbActiveModal);

  error = false;

  readonly form = new FormGroup({
    email: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, Validators.email],
    }),
    password: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  login(): void {
    this.authService.login(this.form.getRawValue()).subscribe({
      next: () => {
        this.modal.close();
      },
      error: () => {
        this.error = true;

        setTimeout(() => {
          this.error = false;
        }, 200);
      },
    });
  }
}
