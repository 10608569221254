<form [formGroup]="form" (submit)="save()" class="row g-4">
  <div class="col-12">
    <h2 class="text-center mb-0">
      {{ form.value.id != null ? 'Edit recipe' : 'Create recipe' }}
    </h2>
  </div>

  <div class="col-sm-6">
    <div class="form-group">
      <label for="name" class="required">Name</label>
      <input
        type="text"
        class="form-control"
        formControlName="name"
        name="name"
        id="name"
      />
      @if (form.controls.name.errors?.['required']) {
        <span class="invalid-feedback">This field is required.</span>
      }
      @if (form.controls.name.errors?.['maxlength']) {
        <span class="invalid-feedback">
          This field can only contain 255 characters.
        </span>
      }
    </div>

    <div class="form-group">
      <label for="amount" class="required">Amount</label>
      <input
        type="text"
        class="form-control"
        formControlName="amount"
        name="amount"
        id="amount"
      />
      @if (form.controls.amount.errors?.['required']) {
        <span class="invalid-feedback">This field is required.</span>
      }
      @if (form.controls.amount.errors?.['maxlength']) {
        <span class="invalid-feedback">
          This field can only contain 255 characters.
        </span>
      }
    </div>

    <div class="form-group">
      <label for="minutes" class="required">Duration in minutes</label>
      <input
        type="number"
        class="form-control"
        formControlName="minutes"
        name="minutes"
        id="minutes"
      />
      @if (form.controls.minutes.errors?.['required']) {
        <span class="invalid-feedback">This field is required.</span>
      }
    </div>

    <div class="form-group">
      <label for="ingredients" class="required">Ingredients</label>
      <textarea
        class="form-control"
        formControlName="ingredients"
        name="ingredients"
        id="ingredients"
        rows="10"
      ></textarea>
      @if (form.controls.ingredients.errors?.['required']) {
        <span class="invalid-feedback">This field is required.</span>
      }
    </div>
  </div>

  <div class="col-sm-6">
    <div class="h-100 form-group d-flex flex-column">
      <label for="instructions" class="required">Instructions</label>
      <textarea
        class="form-control flex-grow-1"
        formControlName="instructions"
        name="instructions"
        id="instructions"
        rows="19"
      ></textarea>
      @if (form.controls.instructions.errors?.['required']) {
        <span class="invalid-feedback">This field is required.</span>
      }
    </div>
  </div>

  <div class="col-12">
    <div class="row g-4">
      @if (form.value.id) {
        <div class="col">
          <button
            type="button"
            class="w-100 btn btn-outline-danger"
            (click)="delete()"
          >
            Delete
          </button>
        </div>
      }

      <div class="col">
        <button
          type="submit"
          class="w-100 btn"
          [class.btn-outline-primary]="!error"
          [class.btn-outline-danger]="error"
          [disabled]="form.invalid || form.pristine"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</form>
