import { Component, inject, input, model } from '@angular/core';
import { Ingredient, User } from '@app/_api';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { noop } from 'rxjs';
import { AdminComponent } from '../admin/admin.component';
import { IngredientImageComponent } from '../ingredient-image/ingredient-image.component';
import { UserModalComponent } from '../user-modal/user-modal.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  imports: [AdminComponent, IngredientImageComponent],
})
export class UsersComponent {
  private readonly modalService = inject(NgbModal);

  readonly users = model.required<User[]>();
  readonly ingredients = input.required<Ingredient[]>();

  create(): void {
    const modal = this.modalService.open(UserModalComponent, { size: 'lg' });

    modal.result
      .then(user => {
        this.users.update(users => [...users, user]);
      })
      .catch(noop);
  }

  edit(user: User): void {
    const modal = this.modalService.open(UserModalComponent, { size: 'lg' });
    const component: UserModalComponent = modal.componentInstance;

    component.form.patchValue({ ...user, password: '' });

    modal.result
      .then((u?: User) => {
        this.users.update(users => {
          if (u) {
            const index = users.findIndex(x => x.id === u.id);

            users[index] = u;

            return [...users];
          } else {
            return users.filter(x => x.id !== user.id);
          }
        });
      })
      .catch(noop);
  }
}
