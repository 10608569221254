<form [formGroup]="form" (submit)="login()" class="row g-4">
  <div class="col-12">
    <h2 class="text-center mb-0">Login</h2>
  </div>

  <div class="col-12">
    <div class="form-group">
      <label for="email" class="required">Email</label>
      <input
        type="text"
        class="form-control"
        autocomplete="username"
        formControlName="email"
        name="email"
        id="email"
      />
      @if (form.controls.email.errors?.['required']) {
        <span class="invalid-feedback">This field is required.</span>
      }
      @if (form.controls.email.errors?.['email']) {
        <span class="invalid-feedback"
          >This field must be an email address.</span
        >
      }
      @if (form.controls.email.errors?.['maxlength']) {
        <span class="invalid-feedback">
          This field can only contain 255 characters.
        </span>
      }
    </div>

    <div class="form-group">
      <label for="password" class="required">Password</label>
      <input
        type="password"
        autocomplete="current-password"
        class="form-control"
        formControlName="password"
        name="password"
        id="password"
      />
      @if (form.controls.password.errors?.['required']) {
        <span class="invalid-feedback">This field is required.</span>
      }
    </div>
  </div>

  <div class="col-12">
    <button
      type="submit"
      class="w-100 btn"
      [class.btn-outline-primary]="!error"
      [class.btn-outline-danger]="error"
      [disabled]="form.invalid"
    >
      Login
    </button>
  </div>
</form>
